<template>
  <section class="account-upgrade-area">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base" v-for="(plan, i) in (plans.items || [])" :key="i">
        <card :data="plan" />
      </div>
    </div>
  </section>
</template>

<script>
import Card from '@/views/pages/upgrade/components/Card.vue';
import { mapState } from 'vuex';
export default {
  components: {
    Card
  },
  data() {
    return { };
  },
  computed: {
    ...mapState('accounts', ['plans']),
  },
  beforeCreate() {},
  mounted () {}
};
</script>

<style lang="scss">
.account-upgrade-area {
  .vx-card__collapsible-content {
    height: 100%;

    .vx-card__body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>